export default {
  "rootPath": "/",
  "renderFunction": "onload",
  "chartName": "aleph-chart aleph-pyramid-chart",
  "scripts": [
    {"file": "/js/jQuery-v3.6.0.js"},
    {"file": "/js/jquery-ui.js"},
    {"file": "/js/d3.v5.min.js"},
    {"file": "/visualisationScripts/population-pyramid-chart/js/aleph-pyramidChart-alephConstructObject-script.js"},
    {"file": "/visualisationScripts/population-pyramid-chart/js/aleph-pyramidChart-windowResizeFunction-script.js"},
    {"file": "/visualisationScripts/population-pyramid-chart/js/aleph-pyramidChart-projectLoadFunctions-script.js"},
    {"file": "/visualisationScripts/population-pyramid-chart/js/aleph-pyramidChart-script.js"},
    {"file": "/visualisationScripts/population-pyramid-chart/js/aleph-pyramidChart-genericProjectFunctions-script.js"},
  ],
  "css": [
    { "file": "/visualisationScripts/population-pyramid-chart/css/aleph-futureForces-pyramidChart-style.css" },
    { "file": "/css/aleph-global-colour-palettes.css" },
    { "file": "/css/dstl-global-colour-palettes.css" },
    { "file": "/css/font-awesome.min.css" },
    { "file": "/css/github.min.css" },
    { "file": "/css/jquery-ui.css" },
  ]
}
