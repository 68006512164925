/* eslint-disable import/prefer-default-export */

export const ethnicityOptions = [
  {value: 1, label: "White"},
  {value: 2, label: "Mixed"},
  {value: 3, label: "Indian/Other Asian"},
  {value: 4, label: "Pakistani/Bangladeshi"},
  {value: 5, label: "Black"},
  {value: 6, label: "Other Ethnic Origins"},
];

export const regionOptions = [
/*   {value: 1, label: "East Midlands"},
  {value: 2, label: "East of England"},
  {value: 3, label: "London"},
  {value: 4, label: "North East"},
  {value: 5, label: "North West"},
  {value: 6, label: "South East"},
  {value: 7, label: "South West"},
  {value: 8, label: "West Midlands"},
  {value: 9, label: "Yorkshire and The Humber"},
  {value: 10, label: "Northern Ireland"},
  {value: 11, label: "Scotland"},
  {value: 12, label: "Wales"}, */
  {value: 1, label: "North East"},
  {value: 2, label: "North West"},
  {value: 3, label: "Yorkshire and The Humber"},
  {value: 4, label: "East Midlands"},
  {value: 5, label: "West Midlands"},
  {value: 6, label: "East of England"},
  {value: 7, label: "London"},
  {value: 8, label: "South East"},
  {value: 9, label: "South West"},
  {value: 10, label: "Wales"},
  {value: 11, label: "Scotland"},
  {value: 12, label: "Northern Ireland"},
];
