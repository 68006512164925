/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Range } from "react-range";
import {
  ethnicityOptions,
  regionOptions,
} from "./filterOptions";
import { MultiSelect } from "../../MultiSelect";
import { safelyCallJSFunction } from "../visualisation-functions";
import Message from "../message";

declare const window: any;
const isBrowser = typeof window !== "undefined";

let autoPlayTimerInterval = 0;

const FilterPanel: React.FunctionComponent = () => {
  const START_YEAR = 2019;
  const END_YEAR = 2040;
  const [ethnicityValuesA, setEthnicityValuesA] = useState(ethnicityOptions);
  const [ethnicityValuesB, setEthnicityValuesB] = useState(ethnicityOptions);
  const [regionValuesA, setRegionValuesA] = useState(regionOptions);
  const [regionValuesB, setRegionValuesB] = useState(regionOptions);
  const [value, setValue] = React.useState([START_YEAR]);
  const [lockedYear, setLockedYear] = React.useState<number | null>(null);
  const [autoPlay, setAutoPlay] = React.useState(false);
  const [locked, setLocked] = React.useState(false);
  const [displayCounts, setDisplayCounts] = React.useState(false);
  const [resetAllPyramidsEnabled, setResetAllPyramidsEnabled] = React.useState(false);

  const setAlephYearValue = (value: number[]) => {
    if (isBrowser) {
      const { aleph } = window;
      if (aleph) {
        aleph.pyramidYear = value;

        aleph.pyramidYearIndex = aleph.years.indexOf(
          aleph.pyramidYear.toString()
        );
      }
    }
  }

  const resetPyramidsButtonClicked = () => {
    setValue([START_YEAR]);
    setAlephYearValue([START_YEAR]);
    if (autoPlay) {
      window.clearInterval(autoPlayTimerInterval);
    }
    setAutoPlay(false);
    setLocked(false);
    setLockedYear(null);
    safelyCallJSFunction("freezeOutlines", { "value": "unlock" });
    setDisplayCounts(false);
    safelyCallJSFunction("countsPercents", { "value": "percent" });
    setResetAllPyramidsEnabled(false);
    safelyCallJSFunction("transitionPyramidChart");
    safelyCallJSFunction("updateInformationLabels");
    setEthnicityValuesA(ethnicityOptions);
    setEthnicityValuesB(ethnicityOptions);
    setRegionValuesA(regionOptions);
    setRegionValuesB(regionOptions);
  }

  React.useEffect(() => {
    safelyCallJSFunction("drawPyramidsChart");
  }, []);


  // Whenever a selection list updates, update the aleph values and redraw the chart
  React.useEffect(() => {
    if (isBrowser) {
      const { aleph } = window;
      if (aleph) {
        setResetAllPyramidsEnabled(true);
        aleph.pyramidCurrentScenarios.left.ethnicities = ethnicityValuesA.map((ethnicityValue) => ethnicityValue.value);
        aleph.pyramidCurrentScenarios.left.regions = regionValuesA.map((regionValue) => regionValue.value);

        if (
          aleph.pyramidCurrentScenarios.left.ethnicities.length !== 0 &&
          aleph.pyramidCurrentScenarios.right.ethnicities.length !== 0 &&
          aleph.pyramidCurrentScenarios.left.regions.length !== 0 &&
          aleph.pyramidCurrentScenarios.right.regions.length !== 0
        ) {
          safelyCallJSFunction("buildPyramids", "left", "selector");
        }
      }
    }
  }, [ethnicityValuesA, regionValuesA])

  // Whenever a selection list updates, update the aleph values and redraw the chart
  React.useEffect(() => {
    if (isBrowser) {
      const { aleph } = window;
      if (aleph) {
        setResetAllPyramidsEnabled(true);
        aleph.pyramidCurrentScenarios.right.ethnicities = ethnicityValuesB.map((ethnicityValue) => ethnicityValue.value);
        aleph.pyramidCurrentScenarios.right.regions = regionValuesB.map((regionValue) => regionValue.value);

        if (
          aleph.pyramidCurrentScenarios.left.ethnicities.length !== 0 &&
          aleph.pyramidCurrentScenarios.right.ethnicities.length !== 0 &&
          aleph.pyramidCurrentScenarios.left.regions.length !== 0 &&
          aleph.pyramidCurrentScenarios.right.regions.length !== 0 &&
          aleph.data.pyramid

        ) {
          safelyCallJSFunction("buildPyramids", "right", "selector");
        }
      }
    }
  }, [ethnicityValuesB, regionValuesB])

  const anyFiltersEmpty = () => (
    ethnicityValuesA.length === 0 ||
    ethnicityValuesB.length === 0 ||
    regionValuesA.length === 0 ||
    regionValuesB.length === 0
  )

  const generateWarningMessage = () => {
    const missingFilters = [];
    if (ethnicityValuesA.length === 0) { missingFilters.push("Ethnicities (left)") };
    if (ethnicityValuesB.length === 0) { missingFilters.push("Ethnicities (right)") };
    if (regionValuesA.length === 0) { missingFilters.push("Regions (left)") };
    if (regionValuesB.length === 0) { missingFilters.push("Regions (right)") };
    return missingFilters.join(", ");
  }

  const setSliderValue = (value: number[]) => {
    setValue(value);
    setAlephYearValue(value);
    setResetAllPyramidsEnabled(true);
    safelyCallJSFunction("transitionPyramidChart");
    safelyCallJSFunction("updateInformationLabels");
  }

  const autoPlayTimer = () => {
    if (isBrowser) {
      const { aleph } = window;
      if (aleph) {
        aleph.pyramidYearIndex += 1;
        if (aleph.pyramidYearIndex > aleph.years.length - 1) {
          aleph.pyramidYearIndex = 0;
          window.clearInterval(autoPlayTimerInterval);
          setAutoPlay(false);
        }
        aleph.pyramidYear = aleph.years[aleph.pyramidYearIndex];
        setValue([aleph.pyramidYear]);
        setResetAllPyramidsEnabled(true);
        safelyCallJSFunction("transitionPyramidChart");
        safelyCallJSFunction("updateInformationLabels");
      }
    }
  }

  const playStopButtonClicked = () => {
    if (!autoPlay) {
      autoPlayTimerInterval = window.setInterval(autoPlayTimer, 1000);
    }
    else {
      window.clearInterval(autoPlayTimerInterval);
    }
    setAutoPlay(!autoPlay);
  }

  const lockUnlockButtonClicked = () => {
    const { aleph } = window;
    if (aleph) {
      aleph.lockedYear = value;
      setResetAllPyramidsEnabled(true);
      safelyCallJSFunction("freezeOutlines", { "value": locked ? "unlock" : "lock" })
      setLocked(!locked);
      setLockedYear(aleph.lockedYear);
    }
  }

  const countsPercentButtonClicked = () => {
    setResetAllPyramidsEnabled(true);
    safelyCallJSFunction("countsPercents", { "value": displayCounts ? "percent" : "count" })
    setDisplayCounts(!displayCounts);
  }

  return (
    <section className="px-4 py-0 mx-auto md:px-8 md:py-0">
      <div className="flex justify-center">
        <button
          type="button"
          onClick={() => playStopButtonClicked()}
          className={`w-64 h-6 mx-4 ${autoPlay ? "bg-secondary-600 hover:bg-secondary-700 focus:ring-secondary-500" : "bg-primary-600 hover:bg-primary-700 focus:ring-primary-500"} inline-flex items-center my-1 px-2 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          <span className="flex-1">{autoPlay ? "Stop" : "Play"}</span>
        </button>
        <button
          type="button"
          onClick={() => lockUnlockButtonClicked()}
          className={`w-64 h-6 mx-4 ${locked ? "bg-secondary-600 hover:bg-secondary-700 focus:ring-secondary-500" : "bg-primary-600 hover:bg-primary-700 focus:ring-primary-500"} inline-flex items-center my-1 px-2 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          <span className="flex-1">{locked ? "Unlock Outlines" : "Lock Outlines"}</span>
        </button>
        <button
          type="button"
          onClick={() => countsPercentButtonClicked()}
          className={`w-64 h-6 mx-4 ${displayCounts ? "bg-secondary-600 hover:bg-secondary-700 focus:ring-secondary-500" : "bg-primary-600 hover:bg-primary-700 focus:ring-primary-500"} inline-flex items-center my-1 px-2 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          <span className="flex-1">{displayCounts ? "Display Percentages" : "Display Counts"}</span>
        </button>

        <button
          type="button"
          onClick={() => resetPyramidsButtonClicked()}
          className="w-64 h-6 mx-4 bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 disabled:bg-gray-400 disabled:cursor-not-allowed inline-flex items-center my-1 px-2 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
          disabled={!resetAllPyramidsEnabled}
        >
          <span className="flex-1">Reset Pyramids</span>
        </button>


      </div>
      <div className="px-16 mt-4 mb-8">
        <div className="grid grid-cols-1 gap-2 px-4">
          <Range
            step={1}
            min={START_YEAR}
            max={END_YEAR}
            values={value}
            onChange={(value) => {
              setSliderValue(value)
            }}
            renderMark={({ props, index }) => {
              const isLockedHere = (locked && (Number(index + START_YEAR) === Number(lockedYear)));
              return (
                <div className="flex-col w-16 text-center justify-between" {...props}>
                  <div className="flex-none mb-2 text-sm font-bold whitespace-nowrap">&nbsp;{isLockedHere ? `Locked` : ""}&nbsp;</div>
                  <div className="flex-none mx-auto mt-2 h-3 w-1 bg-primary-600" />
                  <div className="flex-none mt-2 text-sm whitespace-nowrap">
                    {(index === 0 || index + START_YEAR === END_YEAR || (index + START_YEAR) % 2 === 0 ) ? Number(index + START_YEAR) : <span>&nbsp;</span>}
                  </div>
                </div>
              )
            }}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div className="flex-col text-center" {...props}>
                <div className="flex-1 mt-6 mx-auto w-5 h-5 bg-primary-600 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400" />
                <div className="flex-1">&nbsp;</div>
              </div>
            )}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-16 z-50">
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-2">
          <div className="lg:col-start-2 lg:col-span-2">
            <MultiSelect placeholder="Ethnicity" options={ethnicityOptions} value={ethnicityValuesA} onChange={setEthnicityValuesA} />
          </div>
          <div className="lg:col-span-2">
            <MultiSelect placeholder="Region" options={regionOptions} value={regionValuesA} onChange={setRegionValuesA} />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-2">
          <div className="lg:col-span-2">
            <MultiSelect placeholder="Ethnicity" options={ethnicityOptions} value={ethnicityValuesB} onChange={setEthnicityValuesB} />
          </div>
          <div className="lg:col-span-2">
            <MultiSelect placeholder="Region" options={regionOptions} value={regionValuesB} onChange={setRegionValuesB} />
          </div>
        </div>
      </div>
      <div className={`mt-4 ${anyFiltersEmpty() ? "" : "hidden"}`}>
        <Message title="Please complete these selection lists" message={generateWarningMessage()} />
      </div>
    </section>

  )
}

export default FilterPanel;
